import React, {useEffect} from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import {gsap} from 'gsap'
import '../stylings/home.css'

// Assets
import {ReactComponent as BackgroundBase} from '../assets/background_colorful_v3.svg'
import {ReactComponent as BackgroundCutout} from '../assets/background_colorful_hand_v3.svg'
import {ReactComponent as Letters} from "../assets/invicuts_letters.svg";
import {Helmet} from "react-helmet";

const Home = () => {
	useEffect(() => {
		let opening_home_animation = gsap.timeline({})

		if(window.innerWidth > 800) {
			// opening_home_animation.fromTo(".Ink", {transform:"translateX(-50%)", rotation:0}, {top:"-25vh", transform:"translateX(-50%)", rotate:360, duration: 1.5, ease:'elastic'})
			opening_home_animation.fromTo(".HomeInk", {background:"#0e1340", opacity:"1"}, {background:"#B6BED1", opacity:".6", duration: 4, ease: "slow(0.7, 0.7, false)", delay: 4})
			opening_home_animation.from('.PageTitle', {display: "none", duration: 2, opacity: 0, ease: "slow(0.7, 0.7, false)"}, "-=3")
			opening_home_animation.from('.PageSubTitle', {display: "none", duration: 1, opacity: 0, ease: "slow(0.7, 0.7, false)"}, "-=2")
			opening_home_animation.from('.NavButton', {display: "none", duration: 1, opacity: 0, y: () => Math.random()*200, stagger: .25, ease: "slow(0.7, 0.7, false)"}, "-=1")
		}
		else {
			opening_home_animation.from('.NavButton', {display: "none", duration: 1, opacity: 0, y: () => Math.random()*200, stagger: .25, ease: "slow(0.7, 0.7, false)"})
		}
		// Get size length of text
		// const logo = document.querySelectorAll("#invictus_letters path");
		//
		// for (let i=0; i<logo.length; i++) {
		// 	console.log(i + ": " + logo[i].getTotalLength());
		// }
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Invictus BCI</title>
				<meta name="description" content="Official Website of Invictus BCI -- We leverage advances in machine learning and sensor technology to create prosthetic control interfaces that can restore near natural functionality" />
			</Helmet>

			{/*Background Images*/}
			<div className={"BackgroundLayers"}>
				<BackgroundBase className={"PageBackgroundBase"}/>
				<div className={'HomeAccentLayer'}/>
				<div className={'HomeInk'}/>
				<BackgroundCutout className={"PageBackgroundCutout"}/>
			</div>

			{/*Title Page*/}
			<div className={'HomeGridLayer MainPageLayer'}>
				<Nav/>
				<div className={'PageTitle'}>
					<Letters/>
				</div>
				<div className={'PageSubTitle'}>
					We leverage advances in machine learning and sensor technology <br/> to create prosthetic control interfaces <br/> that can restore near natural functionality
				</div>
				<Footer/>
			</div>
		</>
	)
}

export default Home
